.webgl{
    position:fixed;
    top:0;
    left:0;
    outline: none;
    
}

html,body{
    overflow: hidden;
}